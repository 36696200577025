import {
    getFeedbackRequest,
    replyFeedbackRequest,
    deleteFeedbackRequest
} from '@/api/feedback';
import { convertDatesToRange } from '@/utils/converters';

export const state = {
    list: [],
    loading: false,
    filterStatus: {}
}

export const mutations = {
    SET_LIST(state, list) {
        state.list = list;
    },
    SET_LOADING(state, value) {
        state.loading = value
    },
    ADD_TO_LIST(state, item) {
        state.list.push(item);
    },
    SET_FILTER_STATUS(state, item) {
        state.filterStatus[item.key] = item.value;
    },
}

export const actions = {
    async getFeedback({ commit }, params) {
        try {
            commit('SET_LIST', []);
            commit('SET_LOADING', true);
            const { data } = await getFeedbackRequest(params);
            if (data) {
                commit('SET_LIST', data);
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING', false);
        }
    },
    async replyFeedback({ commit }, params) {
        try {
            commit('SET_LOADING', true);
            await replyFeedbackRequest(params.id, params.data);
            this._vm.$showSuccessToast('Feedback successfully replied');
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async deleteFeedback({ commit }, { id, data }) {
        try {
            commit('SET_LOADING', true);
            await deleteFeedbackRequest(id, data);
            this._vm.$showSuccessToast('Feedback deleted successfully');
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING', false);
        }
    },
};

export const getters = {
    employeesOptions(state) {
        return [
            { value: null, text: 'Choose Employee' },
            ...state.list.map(({ uid, name }) => ({ value: uid, text: name }))
        ];
    },
    contractsOptions() {
        const options = state.employee
            ? [...state.employee.contracts]
                .map(({ id, start_date, end_date }) => ({
                    value: id,
                    text: convertDatesToRange(start_date, end_date)
                }))
            : [];

        return [{ value: null, text: 'Choose Contract' }, ...options];
    }
}
