import { getUser, getRoles, deleteRoles, updateUser } from "@/api/permissions";
import { showErrorToast, showSuccessToast } from "@/utils/toasts";

export const state = {
    users: [],
    roles: [],
    loading: false,
};

export const mutations = {
    SET_LIST(state, users = []) {
        state.users = users;
    },
    SET_ROLES(state, roles = []) {
        state.roles = roles;
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
    DELETE_ITEM_FROM_LIST(state, id) {
        state.users = state.users.filter((item) => item.id !== id);
    },
    UPDATE_ITEM_IN_LIST(state, data) {
        state.users = state.users.map((item) => {
            if (item.id === data.id) {
                return { ...data };
            }
            return item;
        });
    },
};

export const actions = {
    async getUsers({ commit }) {
        try {
            commit("SET_LOADING", true);
            const { data } = await getUser();
            commit("SET_LIST", data || []);
        } catch (e) {
            console.error(e);
        } finally {
            commit("SET_LOADING", false);
        }
    },
    async getRoles({ commit }) {
        try {
            commit("SET_LOADING", true);
            const { data } = await getRoles();
            commit("SET_ROLES", data || []);
        } catch (e) {
            console.error(e);
        } finally {
            commit("SET_LOADING", false);
        }
    },
    async deleteRoles({ commit }, payload) {
        try {
            commit("SET_LOADING", true);
            const res = await deleteRoles(payload);
            if (res.code === 200) {
                commit("UPDATE_ITEM_IN_LIST", res.data);
                showSuccessToast("User deleted successfully");
            } else {
                showErrorToast(res.message);
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit("SET_LOADING", false);
        }
    },
    async updateUser({ commit }, payload) {
        try {
            commit("SET_LOADING", true);
            const res = await updateUser(payload);
            commit("UPDATE_ITEM_IN_LIST", res.data);
            if (res.code === 200) {
                showSuccessToast("Operation was successful");
            } else {
                if (res.code === 422) {
                    showErrorToast(res.data);
                } else {
                    showErrorToast("Something went wrong!");
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit("SET_LOADING", false);
        }
    },
};
