import qs from 'qs';
import request from '@/utils/request';

export const getInterviewsRequest = (params) => {
  const queryParam = params ? `?${qs.stringify(params, { skipNulls: true })}` : ''

  return request.get(`/interviews${queryParam}`);
};

export const createInterviewRequest = (data) => request.post('/interviews', data);

export const updateInterviewRequest = (id, data) => request.post(`/interviews/${id}`, data);

export const deleteInterviewRequest = (id, data) => request.post(`/interviews/${id}`, data);

export const loadInterviewCVRequest = (id, data) => request.post(`/interviews/cv/${id}`, data);

export const getInterviewCVRequest = (id) => request.get(`/interviews/cv/${id}`, {
  responseType: 'blob'
});

export const inviteRequest = (data) => request.post('/interviews/invite', data);
