import qs from 'qs';
import request from '@/utils/request';

export const getEmployeesRequest = (params) => {
  const prms = {...params, list: true}
  const queryParam = `?${qs.stringify(prms, { skipNulls: true })}`

  return request.get(`/employees${queryParam}`);
}

export const downloadEmployeeCsv = (params) => {
  const queryParam = params ? `?${qs.stringify(params, { skipNulls: true })}` : ''

  return request.post(`/employees/csv`,queryParam);
}
export const downloadContractCsv = (params) => {
  const queryParam = params ? `?${qs.stringify(params, { skipNulls: true })}` : ''
  return request.post(`/employees/csv-contracts`,queryParam, {
    responseType: 'blob'
  });
}

export const createEmployeeRequest = (data) => request.post('/employees', data);

export const createEmployeeNoteRequest = (id, data) => request.post(`/employees/notes/${id}`, data);

export const updateEmployeeNoteRequest = (noteId, data) => request.post(`/employees/notes/${noteId}`, data);

export const deleteEmployeeNoteRequest = (noteId, data) => request.post(`/employees/notes/${noteId}`, data);

export const getEmployeeRequest = (id) => request.get(`employees/${id}`);

export const updateEmployeeRequest = (id, data) => request.post(`employees/${id}`, data);

export const uploadEmployeeAvatarRequest = (id, data) => request.post(`/employees/photo/${id}`, data);

export const sendFillProfileRequest = (id, data) => request.post(`/employees/send-fill-profile-email/${id}`, data);

export const deleteEmployeeRequest = (id) => request.delete(`/employees/${id}`);

export const addEmployeeManager = (data) => request.post(`/employees/manager/add`, data)

export const deleteEmployeeManager = ({subordinateUid, managerUid, data}) => request.post(`/employees/manager/rm/${subordinateUid}/${managerUid}`, data)

export const addEmployeePaymentManager = (data) => request.post(`/employees/payment-manager/add`, data)

export const deleteEmployeePaymentManager = ({freelancerUid, managerUid, data}) => request.post(`/employees/payment-manager/rm/${freelancerUid}/${managerUid}`, data)
