import request from "@/utils/request";

export const getUser = () => request.get("/permissions/get-users");

export const getRoles = () => request.get("/permissions/get-roles");

export const deleteRoles = (id) =>
    request.post(`/permissions/delete-roles/${id}`);

export const updateUser = ({id, field} = {}) =>
    request.post(`/permissions/assign-roles/${id}`, field);

export const getUserRoles = (id) =>
    request.get(`/permissions/get-users/${id}`);
