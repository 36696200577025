const keycloakAuth = ({Vue, store}, callback) => {
    Vue.$keycloak
        .init({onLoad: "login-required"})
        .then(async (auth) => {
            if (!auth) {
                window.location.reload();
            } else {
                await Vue.$keycloak
                    .loadUserInfo()
                    .then(async (res) => {
                        if (res) {
                            await store.dispatch('user/getMe')
                            setInterval(() => {
                                Vue.$keycloak.updateToken(70)
                            }, 60000);
                            return callback()
                        }
                    });
            }
        })
        .catch(() => {
            console.error('Authenticated Failed')
        });
}

export default keycloakAuth;
