import {
  getEmployeeRequest,
  getEmployeesRequest,
  createEmployeeRequest,
  updateEmployeeRequest,
  sendFillProfileRequest,
  createEmployeeNoteRequest,
  updateEmployeeNoteRequest,
  deleteEmployeeNoteRequest,
  uploadEmployeeAvatarRequest,
  deleteEmployeeRequest,
  addEmployeeManager,
  deleteEmployeeManager,
  addEmployeePaymentManager,
  deleteEmployeePaymentManager,
  downloadEmployeeCsv,
  downloadContractCsv
} from '@/api/employees';
import {
  updateProfileRequest,
  getProfileByIdRequest,
  setProfileVersionByIdRequest,
  uploadProfileAvatarRequest,
  uploadProfilePassportRequest,
  uploadProfileTaxNumberRequest,
  downloadProfileDocumentRequest,
  uploadProfileInternationalPassportRequest
} from '@/api/profile';
import {
  dismissContractRequest,
  deleteContractRequest,
  createContractRequest,
  updateContractRequest
} from '@/api/contracts';
import {
  deleteDocumentRequest,
  createDocumentRequest,
  updateDocumentRequest
} from '@/api/documents';
import { convertDatesToRange } from '@/utils/converters';
import { downloadFile } from '@/utils/files';
import {showErrorToast, showSuccessToast} from "../../utils/toasts";
import {updateScheduleRequest} from "@/api/schedule";

export const state = {
  list: [],
  profile: null,
  employee: null,
  loading: false,
  totalItems: 0,
  filterEmployees: {},
  message:null,
  pagination: null
}

export const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_MESSAGE(state,data){
    state.message = data;
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  ADD_TO_LIST(state, item) {
    state.list.push(item);
  },
  SET_TOTAL_RECORDS(state, count) {
    state.totalItems = count;
  },
  SET_EMPLOYEE(state, value) {
    state.employee = value;
  },
  ADD_NOTE_TO_EMPLOYEE(state, note) {
    state.employee.notes.push(note);
  },
  UPDATE_NOTE_IN_EMPLOYEE(state, note) {
    const oldNote = state.employee.notes.find(({ id }) => id === note.id);
    Object.assign(oldNote, note);
  },
  DELETE_NOTE_FROM_EMPLOYEE(state, noteId) {
    const index = state.employee.notes.findIndex(({ id }) => id === noteId)
    if (index + 1) {
      state.employee.notes.splice(index, 1);
    }
  },
  ADD_CONTRACT_TO_EMPLOYEE(state, contract) {
    state.employee.contracts.push(contract);
  },
  UPDATE_CONTRACT_IN_EMPLOYEE(state, contract) {
    const oldContract = state.employee.contracts.find(({ id }) => id === contract.id);
    Object.assign(oldContract, contract);
  },
  DELETE_CONTRACT_FROM_EMPLOYEE(state, contractId) {
    const index = state.employee.contracts.findIndex(({ id }) => id === contractId)
    if (index + 1) {
      state.employee.contracts.splice(index, 1);
    }
  },
  DELETE_EMPLOYEE(state, employeeId) {
    const index = state.employees.list.findIndex(({ id }) => id === employeeId)
    if (index + 1) {
      state.employees.list.splice(index, 1);
    }
  },
  ADD_DOCUMENT_TO_EMPLOYEE(state, document) {
    state.employee.document_links.push(document);
  },
  UPDATE_DOCUMENT_IN_EMPLOYEE(state, document) {
    const oldDocument = state.employee.document_links.find(({ id }) => id === document.id);
    if (oldDocument) {
      Object.assign(oldDocument, document);
    }
  },
  DELETE_DOCUMENT_FROM_EMPLOYEE(state, documentId) {
    const index = state.employee.documents.findIndex(({ id }) => id === documentId)
    if (index + 1) {
      state.employee.documents.splice(index, 1);
    }
  },
  SET_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_FILTER_EMPLOYEES(state, payload) {
    state.filterEmployees[payload.key] = payload.value;
  },
  SET_NULL_FILTER_EMPLOYEE (state) {
    state.filterEmployees = {}
  },
  SET_PAGINATION_SETTING (state, settings) {
    state.pagination = settings
  }
}

export const actions = {
  async getEmployees({ commit }, params) {
    try {
      commit('SET_LIST', []);
      commit('SET_LOADING', true);
      const { data } = await getEmployeesRequest(params);
      if (data.data) {
        commit('SET_LIST', data.data);
        commit('SET_TOTAL_RECORDS', data.total || 0);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async exportEmployeesCsv({ commit }, params) {
    try {
      commit('SET_LOADING', true);
      const data = await downloadEmployeeCsv(params);
      commit('SET_MESSAGE',data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async downloadContractCsv({ commit }, params) {
    try {
      commit('SET_LOADING', true);
      const data = await downloadContractCsv(params);
      if (data) {
        const blob = new Blob([data], { type: data.type })
        downloadFile(blob, `contracts-${this._vm.$moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createEmployee({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await createEmployeeRequest(payload);
      if (data) {
        commit('ADD_TO_LIST', data);
        this._vm.$showSuccessToast('Employee created successfully');
        return data;
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async createEmployeeNote({ commit, state }, { id, data: note }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await createEmployeeNoteRequest(id, note);
      if (state.employee) {
        commit('ADD_NOTE_TO_EMPLOYEE', data);
      }
      this._vm.$showSuccessToast('Note created successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async updateEmployeeNote({ commit, state }, { id, data: note }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await updateEmployeeNoteRequest(id, note);
      if (state.employee) {
        commit('UPDATE_NOTE_IN_EMPLOYEE', data);
      }
      this._vm.$showSuccessToast('Note updated successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async deleteEmployeeNote({ commit, state }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      await deleteEmployeeNoteRequest(id, data);
      if (state.employee) {
        commit('DELETE_NOTE_FROM_EMPLOYEE', id);
      }
      this._vm.$showSuccessToast('Note deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getEmployeeById({ commit }, id) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getEmployeeRequest(id);
      commit('SET_EMPLOYEE', data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async updateEmployee({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const { data: respose } = await updateEmployeeRequest(id, data);
      commit('SET_EMPLOYEE', respose);
      this._vm.$showSuccessToast('Employee updated successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  clearEmployee({ commit }) {
    commit('SET_EMPLOYEE', null);
  },

  async uploadEmployeeAvatar({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      await uploadEmployeeAvatarRequest(id, data);
      this._vm.$showSuccessToast('Employee avatar upload successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async createContract({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await createContractRequest(payload);
      commit('ADD_CONTRACT_TO_EMPLOYEE', data);
      this._vm.$showSuccessToast('Contract created successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async updateContract({ commit }, { id, data: contract }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await updateContractRequest(id, contract);
      commit('UPDATE_CONTRACT_IN_EMPLOYEE', data);
      this._vm.$showSuccessToast('Contract updated successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async dismissContract({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const res = await dismissContractRequest(id, data);
      this._vm.$showSuccessToast('Contract dismissed successfully');
      return res
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async deleteContract({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      await deleteContractRequest(id, data);
      commit('DELETE_CONTRACT_FROM_EMPLOYEE', id);
      this._vm.$showSuccessToast('Contract deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async deleteEmployee({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const result = await deleteEmployeeRequest(id, data);
      if (result.code === 200) {
        this._vm.$showSuccessToast('Employee deleted successfully');
      }
      return result;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async createDocument({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await createDocumentRequest(payload);
      if (data.code === 200) {
        commit('ADD_DOCUMENT_TO_EMPLOYEE', data?.data);
        this._vm.$showSuccessToast('Document created successfully');
        return data
      } else {
        this._vm.$showErrorToast(`${data.message}`);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async updateDocument({ commit }, { id, data: document }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await updateDocumentRequest(id, document);
      commit('UPDATE_DOCUMENT_IN_EMPLOYEE', data);
      this._vm.$showSuccessToast('Document updated successfully');
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async deleteDocument({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      await deleteDocumentRequest(id, data);
      commit('DELETE_DOCUMENT_FROM_EMPLOYEE', id);
      this._vm.$showSuccessToast('Document deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async sendFillProfile({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      await sendFillProfileRequest(id, data);
      this._vm.$showSuccessToast('Profile filled successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getProfileById({ commit }, id) {
    try {
      commit('SET_LOADING', true);
      const { data } = await getProfileByIdRequest(id);
      commit('SET_PROFILE', data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async setProfileVersionById({ commit }, id) {
    try {
      commit('SET_LOADING', true);
      const { data } = await setProfileVersionByIdRequest(id);
      commit('SET_PROFILE', data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  clearProfile({ commit }) {
    commit('SET_PROFILE', null);
  },

  async uploadProfileAvatar({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const response = await uploadProfileAvatarRequest(id, data);
      commit('SET_PROFILE', response.data)
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async uploadProfilePassport({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const response = await uploadProfilePassportRequest(id, data);
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async uploadProfileTaxNumber({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const response = await uploadProfileTaxNumberRequest(id, data);
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async uploadProfileInternationalPassport({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const response = await uploadProfileInternationalPassportRequest(id, data);
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async updateProfile({ commit }, { id, data: profile }) {
    try {
      commit('SET_LOADING', true);
      const response = await updateProfileRequest(id, profile);
      commit('SET_PROFILE', response.data);
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async downloadProfileDocument({ commit }, path) {
    try {
      commit('SET_LOADING', true);
      const data = await downloadProfileDocumentRequest(path);
      const blob = new Blob([data], { type: data.type })
      const urls = path.split('/');
      downloadFile(blob, urls[urls.length - 1]);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async addManager({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await addEmployeeManager(payload);
      if (data.code === 200) {
        showSuccessToast(`${data.data.message}`)
        return data
      }
    } catch (e) {
      showErrorToast(e ?? 'Something went wrong')
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async removeManager({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await deleteEmployeeManager(payload);
      if (data.code === 200) {
        showSuccessToast(`${data.data.message}`)
        return new Promise((resolve) => {
          resolve(true)
        })
      } else {
        showErrorToast('Something went wrong')
      }
    } catch (e) {
      showErrorToast('Something went wrong')
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async addPaymentManager({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await addEmployeePaymentManager(payload);
      if (data.code === 200) {
        showSuccessToast(`${data.data.message}`)
        return new Promise((resolve) => {
          resolve(true)
        })
      } else {
        showErrorToast('Something went wrong')
      }
    } catch (e) {
      showErrorToast('Something went wrong')
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async removePaymentManager({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await deleteEmployeePaymentManager(payload);
      if (data.code === 200) {
        showSuccessToast(`${data.data.message}`)
        return new Promise((resolve) => {
          resolve(true)
        })
      } else {
        showErrorToast('Something went wrong')
      }
    } catch (e) {
      showErrorToast('Something went wrong')
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateSchedule({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const data = await updateScheduleRequest(payload.employeeUid, payload.data);
      if (data.code === 200) {
        showSuccessToast('Schedule successfully save')
        return new Promise((resolve) => {
          resolve(true)
        })
      } else {
        showErrorToast('Something went wrong')
      }
    } catch (e) {
      showErrorToast('Something went wrong')
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export const getters = {
  employeesOptions(state) {
    return [
      { value: null, text: 'Choose Employee' },
      ...state.list.map(({ uid, name }) => ({ value: uid, text: name }))
    ];
  },
  contractsOptions: (state) =>  [
    { value: null, text: 'Choose Contract' },
    ...state.employee.contracts
      ?.map(({ id, start_date, end_date }) => ({
        value: id,
        text: convertDatesToRange(start_date, end_date)
      }))
  ],
  employee(state){
    return state.employee;
  },
  message(state){
    return state.message;
  },
  getLimitPage (state) {
    return state.pagination?.limit
  }
}
