import Vue from 'vue';
import * as DepartmentsApi from '@/api/departments';
import * as PositionsApi from '@/api/positions';
import * as CategoriesApi from '@/api/categories';
import * as LocationsApi from '@/api/locations'
import * as PaymentSchemesApi from '@/api/payment-schemes';
import * as PaymentTypesApi from '@/api/payment-types';
import * as LegalStatusesApi from '@/api/legal-statuses';

import { getSettingsRequest } from "@/api/settings";

export const state = {
  companies: [],
  positions: [],
  departments: [],
  locations: [],
  categories: [],
  documentTypes: [],
  paymentSchemes: [],
  paymentTypes: [],
  legalStatuses: [],
  duties: null,
  loading: false
}

export const mutations = {
  SET_LIST(state, { list, elements }) {
    state[list] = elements;
  },
  SET_SETTINGS(state, data) {
    Object.keys(data).forEach((key) => {
      state[key] = data[key]
    })
  },
  ADD_ITEM_TO_LIST(state, { list, item }) {
    state[list].push(item);
  },
  UPDATE_ITEM(state, { list, item }) {
    const index = state[list].findIndex(({ id }) => id === item.id)
    if (index + 1) {
      Vue.set(state[list], index, item);
    }
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  DELETE_ITEM(state, { list, itemId }) {
    const index = state[list].findIndex(({ id }) => id === itemId)
    if (index + 1) {
      state[list].splice(index, 1);
    }
  },
  UPDATE_ACTIVE_DUTIES (state, obj) {
    state.duties = obj
  }
}

export const actions = {
  async getSettingsList({ commit }) {
    try {
      commit('SET_SETTINGS', {});
      commit('SET_LOADING', true);
      const { data } = await getSettingsRequest();
      if (data) {
        commit('SET_SETTINGS', data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getDepartmentsList({ commit }) {
    try {
      commit('SET_LIST', []);
      commit('SET_LOADING', true);
      const { data } = await DepartmentsApi.getDepartmentsRequest();
      if (data) {
        commit('SET_LIST', { list: 'departments', elements: data });
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createDepartment({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await DepartmentsApi.createDepartmentRequest(payload);
      if (data) {
        commit('ADD_ITEM_TO_LIST', { list: 'departments', item: data });
      }
      this._vm.$showSuccessToast('Department created successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateDepartment({ commit }, { id, data: depData }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await DepartmentsApi.updateDepartmentRequest(id, depData);
      commit('UPDATE_ITEM', { list: 'departments', item: data });
      this._vm.$showSuccessToast('Project updated successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteDepartment({ commit }, { data, id }) {
    try {
      commit('SET_LOADING', true);
      await DepartmentsApi.deleteDepartmentRequest(id, data);
      commit('DELETE_ITEM', { list: 'departments', itemId: id });
      this._vm.$showSuccessToast('Department deleted successfully');
    } catch (e) {
      console.error(e);
      this._vm.$showErrorToast(e ?? 'Something went wrong');
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getPositionsList({ commit }) {
    try {
      commit('SET_LIST', []);
      commit('SET_LOADING', true);
      const { data } = await PositionsApi.getPositionsRequest();
      if (data) {
        commit('SET_LIST', { list: 'positions', elements: data });
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createPosition({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await PositionsApi.createPositionRequest(payload);
      commit('ADD_ITEM_TO_LIST', { list: 'positions', item: data });
      this._vm.$showSuccessToast('Position created successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updatePosition({ commit, dispatch }, { id, data: positionData }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await PositionsApi.updatePositionRequest(id, positionData);
      if (data) {
        await commit('UPDATE_ITEM', { list: 'positions', item: data });
        await dispatch('getPositionsList');
      }
      this._vm.$showSuccessToast('Position updated successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deletePosition({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      await PositionsApi.deletePositionRequest(id, data);
      commit('DELETE_ITEM', { list: 'positions', itemId: id });
      this._vm.$showSuccessToast('Position deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async createDuties({ commit, dispatch }, payload) {
    try {
      commit('SET_LOADING', true);
      await PositionsApi.createDuties(payload).then(res => {
        if (res.code === 200 || res.code === 201) {
          dispatch('getPositionsList')
          this._vm.$showSuccessToast('Duties created successfully');
          commit('UPDATE_ACTIVE_DUTIES', res.data)
        } else {
          this._vm.$showErrorToast(`${res.message}`);
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteDuties({ commit, dispatch }, payload) {
    try {
      commit('SET_LOADING', true);
      await PositionsApi.deleteDuties(payload).then(res => {
        if (res.code === 200 || res.code === 201) {
          dispatch('getPositionsList')
          this._vm.$showSuccessToast('Duties deleted');
        } else {
          this._vm.$showErrorToast(`${res.message}`);
        }
      })
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateDuties({ commit, dispatch }, payload) {
    try {
      commit('SET_LOADING', true);
      await PositionsApi.updateDuties(payload.id, payload.data).then(res => {
        if (res.code === 200 || res.code === 201) {
          dispatch('getPositionsList')
          this._vm.$showSuccessToast('Duties updated');
        } else {
          this._vm.$showErrorToast(`${res.message}`);
        }
      })
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getCategoriesList({ commit }) {
    try {
      commit('SET_LIST', []);
      commit('SET_LOADING', true);
      const { data } = await CategoriesApi.getCategoriesRequest();
      if (data) {
        commit('SET_LIST', { list: 'categories', elements: data });
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createCategory({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await CategoriesApi.createCategoryRequest(payload);
      if (data) {
        commit('ADD_ITEM_TO_LIST', { list: 'categories', item: data });
      }
      this._vm.$showSuccessToast('Category created successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateCategory({ commit }, { id, data: categoryData }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await CategoriesApi.updateCategoryRequest(id, categoryData);
      if (data) {
        commit('UPDATE_ITEM', { list: 'categories', item: data });
      }
      this._vm.$showSuccessToast('Category updated successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteCategory({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      await CategoriesApi.deleteCategoryRequest(id, data);
      commit('DELETE_ITEM', { list: 'categories', itemId: id });
      this._vm.$showSuccessToast('Category deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getLocationsList({ commit }) {
    try {
      commit('SET_LIST', []);
      commit('SET_LOADING', true);
      const { data } = await LocationsApi.getLocationsRequest();
      if (data) {
        commit('SET_LIST', { list: 'locations', elements: data });
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createLocation({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await LocationsApi.createLocationRequest(payload);
      if (data) {
        commit('ADD_ITEM_TO_LIST', { list: 'locations', item: data });
      }
      this._vm.$showSuccessToast('Location created successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateLocation({ commit }, { id, data: locationData }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await LocationsApi.updateLocationRequest(id, locationData);
      if (data) {
        commit('UPDATE_ITEM', { list: 'locations', item: data });
      }
      this._vm.$showSuccessToast('Project updated successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteLocation({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      await LocationsApi.deleteLocationRequest(id, data);
      commit('DELETE_ITEM', { list: 'locations', itemId: id });
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getPaymentSchemesList({ commit }) {
    try {
      commit('SET_LIST', []);
      commit('SET_LOADING', true);
      const { data } = await PaymentSchemesApi.getPaymentSchemesRequest();
      if (data) {
        commit('SET_LIST', { list: 'paymentSchemes', elements: data });
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createPaymentScheme({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await PaymentSchemesApi.createPaymentSchemeRequest(payload);
      if (data) {
        commit('ADD_ITEM_TO_LIST', { list: 'paymentSchemes', item: data });
      }
      this._vm.$showSuccessToast('Payment scheme created successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updatePaymentScheme({ commit }, { id, data: schemeData }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await PaymentSchemesApi.updatePaymentSchemeRequest(id, schemeData);
      if (data) {
        commit('UPDATE_ITEM', { list: 'paymentSchemes', item: data });
      }
      this._vm.$showSuccessToast('Payment scheme updated successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deletePaymentScheme({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      await PaymentSchemesApi.deletePaymentSchemeRequest(id, data);
      commit('DELETE_ITEM', { list: 'paymentSchemes', itemId: id });
      this._vm.$showSuccessToast('Payment scheme deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getPaymentTypesList({ commit }) {
    try {
      commit('SET_LIST', []);
      commit('SET_LOADING', true);
      const { data } = await PaymentTypesApi.getPaymentTypesRequest();
      if (data) {
        commit('SET_LIST', { list: 'paymentTypes', elements: data });
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createPaymentType({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await PaymentTypesApi.createPaymentTypeRequest(payload);
      if (data) {
        commit('ADD_ITEM_TO_LIST', { list: 'paymentTypes', item: data });
      }
      this._vm.$showSuccessToast('Payment type created successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updatePaymentType({ commit }, { id, data: typeData }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await PaymentTypesApi.updatePaymentTypeRequest(id, typeData);
      if (data) {
        commit('UPDATE_ITEM', { list: 'paymentTypes', item: data });
      }
      this._vm.$showSuccessToast('Payment type updated successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deletePaymentType({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      await PaymentTypesApi.deletePaymentTypeRequest(id, data);
      commit('DELETE_ITEM', { list: 'paymentTypes', itemId: id });
      this._vm.$showSuccessToast('Payment type deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getLegalStatusesList({ commit }) {
    try {
      commit('SET_LIST', []);
      commit('SET_LOADING', true);
      const { data } = await LegalStatusesApi.getLegalStatusesRequest();
      if (data) {
        commit('SET_LIST', { list: 'legalStatuses', elements: data });
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createLegalStatus({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await LegalStatusesApi.createLegalStatuseRequest(payload);
      if (data) {
        commit('ADD_ITEM_TO_LIST', { list: 'legalStatuses', item: data })
      }
      this._vm.$showSuccessToast('Legal status created successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateLegalStatus({ commit }, { id, data: statusData }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await LegalStatusesApi.updateLegalStatuseRequest(id, statusData);
      if (data) {
        commit('UPDATE_ITEM', { list: 'legalStatuses', item: data });
      }
      this._vm.$showSuccessToast('Legal status updated successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteLegalStatus({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      await LegalStatusesApi.deleteLegalStatuseRequest(id, data);
      commit('DELETE_ITEM', { list: 'legalStatuses', itemId: id });
      this._vm.$showSuccessToast('Legal status deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export const getters = {
  departmentsOptions(state) {
    return [
      { value: null, text: 'Choose Department' },
      ...state.departments
        .map(({ id, title }) => ({ value: id, text: title }))
    ];
  },
  categoriesOptions: (state) => [
    { value: null, text: 'Choose Category' },
    ...state.categories
      .map(({ id, title }) => ({ value: id, text: title }))
  ],
  // categoriesOptions() {
  //   return [
  //     { value: null, text: 'Choose Category' },
  //     ...state.departments
  //       .map(({ id, title }) => ({ value: id, text: title }))
  //   ];
  // },
  locationsOptions: (state) => [
    { value: null, text: 'Choose Location' },
    ...state.locations
      .map(({ id, title }) => ({ value: id, text: title }))
  ],
  positionsOptions: (state) => state.positions.map(({ id, title,department_id }) => ({ value: id, text: title,department:department_id })),
  legalStatusesOptions: (state) =>  [
    { value: null, text: 'Choose Legal Status' },
    ...state.legalStatuses
      .map(({ id, title }) => ({ value: id, text: title }))
  ],
  paymentSchemesOptions: (state) =>  [
    { value: null, text: 'Choose Payment Scheme' },
    ...state.paymentSchemes
      .map(({ id, title }) => ({ value: id, text: title }))
  ],
  paymentTypesOptions: (state) =>  [
    { value: null, text: 'Choose Payment Type' },
    ...state.paymentTypes
        .map(({ id, title }) => ({ value: id, text: title }))
  ],
  documentTypesOptions: (state) =>  [
    { value: null, text: 'Choose Document Type' },
    ...state.documentTypes
      .map(({ id, title }) => ({ value: id, text: title }))
  ]
}

