<script>
import appConfig from "@/app.config";

import { notificationMethods } from "@/state/helpers";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  async mounted() {
      if(!this.user.roles.some(role => role === 'admin' || role === 'hr')){
        await this.$router.replace('/interviews');
      }
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearNotification();
    }
  },
  methods: {
    clearNotification: notificationMethods.clear
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  }
};
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>
