import request from '@/utils/request';

export const getPositionsRequest = () => request.get('/positions/');

export const createPositionRequest = (data) => request.post('/positions/', data);

export const updatePositionRequest = (id, data) => request.post(`/positions/${id}`, data);

export const deletePositionRequest = (id, data) => request.post(`/positions/${id}`, data);

export const createDuties = (data) => request.post(`/duties`, data);

export const updateDuties = (id, data) => request.post(`/duties/${id}`, data);

export const deleteDuties = ({id, data}) => request.post(`/duties/${id}`, data);
