import Vue from 'vue'
import moment from 'moment';
import Swal from "sweetalert2";


export const Prototypes = () => {
    Vue.prototype.$moment = moment
    Vue.prototype.$convertObjectToFormData = (obj) => {
        const data = new FormData();
        for (const [key, value] of Object.entries(obj)) {
            if (Array.isArray(value)) {
                value.forEach((item, index) => data.append(`${key}[${index}]`, item));
            } else {
                data.append(key, value);
            }
        }

        return data;
    }
    Vue.prototype.$showSuccessToast = (message) => {
        Swal.fire({
            toast: true,
            timer: 2000,
            icon: 'success',
            title: 'Success',
            position: 'bottom-end',
            text: message,
            timerProgressBar: false,
            showConfirmButton: false,
        });
    }
    Vue.prototype.$showErrorToast = (message) => {
        Swal.fire({
            toast: true,
            timer: 2000,
            icon: 'error',
            title: 'Error',
            position: 'bottom-end',
            html: message,
            timerProgressBar: false,
            showConfirmButton: false,
        });
    }
}
