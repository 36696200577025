import moment from 'moment';
import { getEventsRequest } from '@/api/events';

export const state = {
    list: [],
    loading: false,
    pageCount: 1,
}

export const mutations = {
    SET_LIST(state, list) {
        state.list = list;
    },
    SET_LOADING(state, value) {
        state.loading = value
    },
    ADD_TO_LIST(state, items) {
        state.list.push(...items);
    },
    SET_PAGE_COUNT(state, count) {
        state.pageCount = count;
    }
}

export const actions = {
    async getEvents({ commit }, params) {
        try {
            const { page } = params;
            document.body.style.overflow = 'hidden';
            if (page === 1) {
                commit('SET_LIST', []);
            }
            commit('SET_LOADING', true);
            const { data } = await getEventsRequest(params);
            if (data) {
                commit('SET_PAGE_COUNT', data.last_page);
                commit('ADD_TO_LIST', data.data);
                return data;
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING', false);
            document.body.style.overflow = 'visible';
        }
    },
    deleteEvents({ commit }) {
        commit('SET_LIST', []);
        commit('SET_PAGE_COUNT', 1);
    }
};

export const getters = {
    gruppedEventsByDate(state) {
        const dates = new Set(state.list.map(({ created_at }) => moment(created_at).format('DD.MM.YYYY')));

        return [...dates].reduce((acc, date) => {
            acc[date] = state.list
                .filter(({ created_at }) => moment(created_at).format('DD.MM.YYYY') === date)

            return acc;
        }, {});
    }
}
