import Vue from 'vue'
import { Directives } from './plugins/directives'
import { Prototypes } from "./plugins/prototypes";
import BootstrapVue from 'bootstrap-vue'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/index'
import VueSweetalert2 from 'vue-sweetalert2';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import Auth from '@/plugins/auth';
import keycloakAuth from '@/utils/keycloakAuth';

import '/src/assets/style/main.scss'
import "@/design/index.scss";



import store from '@/state/store'

import App from './App.vue'

import '@/plugins/global.js'
import 'swiper/css/swiper.css'



Vue.use(VueRouter)
Vue.use(vco)
Vue.use(Directives)
Vue.use(Prototypes)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueSweetalert2);
Vue.use(VueAwesomeSwiper);
Vue.use(Auth)


keycloakAuth({Vue, store}, () => {
      new Vue({
        el: "#app",
        router,
        store,
        render: (h) => h(App),
      })
    }
)
