import Vue from 'vue';
import Keycloak from 'keycloak-js';

let initOptions = {
    url: process.env.VUE_APP_OIDC_URL,
    realm: process.env.VUE_APP_OIDC_REALM,
    clientId: process.env.VUE_APP_OIDC_CLIENT_ID,
    onLoad: "login-required",
};

const _keycloak = new Keycloak(initOptions);


const Plugin = {
    install: (Vue) => {
        Vue.$keycloak = _keycloak;
        Object.defineProperties(Vue.prototype, {
            $keycloak: {
                get() {
                    return _keycloak;
                },
            },
        });
    }
}


Vue.use(Plugin);


export default Plugin
