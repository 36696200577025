import Vue from 'vue';
import { hasResourceRole } from "@/state/helpers";
import {isArray} from "lodash";
//mixins
import GlobalMixin from '@/mixins/global-mixin'
Vue.mixin(GlobalMixin);

//components
import VTable from '@/components/VTable.vue';
Vue.component('VTable',VTable);

//prototypes
Vue.prototype.$hasAccess = (roles) => {
    return isArray(roles) ? roles.some((role) => hasResourceRole(role) || role === 'all') : false
}
