import Vue from 'vue'

export const Directives = () => {
    Vue.directive('load', (el, binding) => {
        try {
            if (binding.value) {
                const findLoader = el.getElementsByClassName('loader')
                if (findLoader.length > 0) {
                    findLoader.forEach(el => {
                        el.remove()
                    })
                }
                const divLoader = document.createElement('div')
                divLoader.classList.add('loader')
                const spanParent = document.createElement('span')
                const spanChild = document.createElement('span')

                spanParent.classList.add('spinner-border', 'text-primary')
                spanParent.style.width = '4rem'
                spanParent.style.height = '4rem'
                spanParent.style.borderWidth = '0.4rem'

                spanChild.classList.add('sr-only')
                spanChild.textContent = 'Loading...'
                spanParent.appendChild(spanChild)
                divLoader.appendChild(spanParent)
                el.append(divLoader)
            } else {
                const findLoader = (el?.getElementsByClassName('loader') || [])
                findLoader[0]?.remove()
            }
        } catch (e) {
            return false;
        }
    })
    // arrow for tabs bootstrap
    Vue.directive('arrow', (el, binding) => {
        function moveTabs () {
            const tabs = el.getElementsByClassName('nav-tabs')[0]
            const maxWidth = tabs.scrollWidth - tabs.clientWidth
            if (maxWidth > tabs.scrollLeft) {
                for (let i=0; i < maxWidth; i += 50) {
                    tabs.scrollLeft += i
                }
            } else {
                tabs.scrollLeft = 0
            }
        }
        try {
            if (binding.value) {
                const findArrow = el.getElementsByClassName('arrow')
                if (findArrow.length > 0) {
                    findArrow.forEach(el => {
                        el.remove()
                    })
                }
                const div = document.createElement('div')
                const i = document.createElement('i')
                i.innerHTML = '                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">\n' +
                    '                  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>\n' +
                    '                </svg>'
                div.classList.add('arrow')
                div.appendChild(i)
                div.addEventListener('click', () => {
                    moveTabs()
                })
                el.appendChild(div)
            }
        } catch {
            return false
        }
    })

    Vue.directive('required-icon', (el, binding) => {
        try {
            if (binding.value) {
                const findIcon = el.querySelectorAll('.label-icon')
                if (findIcon.length > 0) {
                    findIcon.forEach(el => {
                        el.remove()
                    })
                }
                const div = document.createElement('div')
                const span = document.createElement('span')
                span.innerHTML = '*'
                span.style.color = 'red'
                div.classList.add('label-icon')
                div.appendChild(span)
                el.getElementsByTagName('label')[0].appendChild(div)
            }
        } catch (e) {
            console.log(e, 'error render for directive required-icon')
            return false
        }
    })
}

