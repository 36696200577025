export default{
    data(){
        return{}
    },
    methods:{
        csvDownload(data){
            let csvFile = new Blob([data], { type: "text/csv" });
            let downloadLink = document.createElement("a");
            downloadLink.download = 'employees_'+this.$moment().format('DDMMYYYY')+'.csv';
            downloadLink.href = window.URL.createObjectURL(csvFile);
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
        },
        groupByKey(array, key) {
            return array 
              .reduce((hash, obj) => {
                if(obj[key] === undefined) return hash; 
                return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
              }, {})
        },
        createFormData(f_d, data, p_key) {
            if (
                data &&
                typeof data === "object" &&
                !(data instanceof Date) &&
                !(data instanceof File)
            ) {
                Object.keys(data).forEach((key) => {
                    this.createFormData(
                        f_d,
                        data[key],
                        p_key ? `${p_key}[${key}]` : key
                    );
                });
            } else {
                const value = data == null ? "" : data;
                f_d.append(p_key, value);
            }
            return f_d;
        },
        toFormData(data) {
            const result = this.createFormData(new FormData(), data);
            return result;
        },
    }
}
