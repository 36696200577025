import request from '@/utils/request';

export const getProfileByIdRequest = (id) => request.get(`/profiles/${id}`);
export const setProfileVersionByIdRequest = (id) => request.get(`/profiles/${id}/setversion`);

export const uploadProfileAvatarRequest = (id, data) => request.post(`/profiles/document/${id}/photo`, data);

export const uploadProfilePassportRequest = (id, data) => request.post(`/profiles/document/${id}/passport`, data);

export const uploadProfileTaxNumberRequest = (id, data) => request.post(`/profiles/document/${id}/tax_number`, data);

export const uploadProfileInternationalPassportRequest = (id, data) => request.post(`/profiles/document/${id}/international_passport`, data);

export const updateProfileRequest = (id, data) => request.post(`/profiles/${id}`, data);

export const downloadProfileDocumentRequest = (path) => request.get(`/profiles/document?path=${path}`, {
  responseType: 'blob'
});

