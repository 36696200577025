export const state = {
    menu: {
        activeId: null
    }
}

export const mutations = {
    SET_ACTIVE_MENU (state, id) {
        state.menu.activeId = id
    }
}