import axios from 'axios';
import { showErrorToast } from '@/utils/toasts';
import Vue from "vue";

export const API_PATH = process.env.VUE_APP_API_PATH ? process.env.VUE_APP_API_PATH : 'https://hrc.mybimiboo.com/api';

axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true;

const service = axios.create({ baseURL: API_PATH });
service.interceptors.request.use(
    function (config) {
        const token = Vue.$keycloak.token;
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
service.interceptors.response.use(
  (res) => {
    const { data } = res;
    if (data.code) {
      if (data.code === 200) {
        return data;
      } else {
        let message = data.message;
        if(data?.data?.length > 0){
            for (const dataKey in data.data) {
                message += '<br> - ' + data.data[dataKey];
            }
        }
        showErrorToast(message);
        throw data.message;
      }
    } else {
      return data;
    }
  },
  (error) => {
    showErrorToast(error.message);
  }
);

export default service;
