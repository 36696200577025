<template>
  <div>
    <slot name="card-header">
      <div class="d-flex align-items-center justify-content-between mb-0 p-2">
        <h4 class="mb-0">{{ title }}</h4>
        <b-button
            variant="primary"
            @click="onAddButtonClick"
        >
          <i class="bx bx-list-plus font-size-20 align-middle mr-2"></i>
          {{ buttonTitle }}
        </b-button>
      </div>
    </slot>
    <div
        v-if="list.length"
        class="table-responsive"
    >
      <div v-if="search">
        <div class="form-group has-search px-2">
          <span class="fa fa-search form-control-feedback"></span>
          <input
              type="search"
              class="form-control form-control-solid"
              @input="searchInput"
              name="search"
              v-model="searchText"
              placeholder="Type to search...">
        </div>
      </div>
      <table class="table table-hover mb-0" :id="tableID">
        <thead class="thead-light">
        <tr>
          <th class="id-column"><div>#</div></th>
          <th v-for="(head,index) in header" :key="index" v-html="head.title" />
          <th class="actions-buttons">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(item, index) in list"
            :key="index+'tr'"
        >
          <td class="id-column"><div>{{ index + 1 }}</div></td>
          <td v-for="(head,index) in header" :key="index+'td'">
            <slot :name="head.key" :item="item" />
          </td>
          <td class="actions-buttons" v-if="actions">
            <div>
              <slot name="icons" :item="item"/>
              <i
                  class="bx bx-pencil text-warning font-size-20 mr-3"
                  @click="onEditIconClick(item)"
              />
              <i
                  class="bx bx-trash text-danger font-size-20"
                  @click="onDeleteIconClick(item)"
              />
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleTable',
  props:{
    header:{
      type:Array,
      default:()=>([{key:'title',title:'Title'}])
    },
    list: {
      type:Array,
    },
    title:{
      type:String,
      default:''
    },
    buttonTitle:{
      type:String,
      default:''
    },
    actions:{
      type:Boolean,
      default:true,
    },
    search:{
      type:Boolean,
      default:true,
    }
  },
  data(){
    return{
      tableID:'custom_table',
      searchText:'',
    }
  },
  methods: {
    onAddButtonClick() {
      this.$emit('onAdd');
    },
    onEditIconClick(item) {
      this.$emit('onEdit', item);
    },
    onDeleteIconClick(row) {
      this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete ${row.title}`, {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then((value) => {
            if (value) {
              this.$emit('onDelete', row.id);
            }
          });
    },
    searchInput(){
      let input, filter, table, trs, tds, i, i2;
      input = this.searchText;
      this.from = 1;
      filter = input.toUpperCase();
      table = document.getElementById(this.tableID);
      trs = table.tBodies[0].getElementsByTagName("tr");
      for(i = 0; i < trs.length; i++){
        tds = trs[i].getElementsByTagName("td");
        trs[i].style.display = "none";
        for ( i2 = 0; i2 < tds.length; i2++){
          if (tds[i2].innerHTML.toUpperCase().indexOf(filter) > -1){
            trs[i].style.display = "";
            continue;
          }
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.actions-buttons {
  width: 120px;
  text-align: right;
  i {
    cursor: pointer;
  }
}
/* Styles for wrapping the search box */

.main {
  width: 50%;
  margin: 50px auto;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
</style>
