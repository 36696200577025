import request from '@/utils/request';

export const createContractRequest = (data) => request.post('/contracts', data);

export const updateContractRequest = (id, data) => request.post(`/contracts/${id}`, data);

export const dismissContractRequest = (id, data) => request.post(`/contracts/dismiss/${id}`, data);

export const deleteContractRequest = (id, data) => request.post(`/contracts/${id}`, data);

