import Vue from 'vue';
import moment from 'moment';
import {
  inviteRequest,
  getInterviewsRequest,
  createInterviewRequest,
  updateInterviewRequest,
  deleteInterviewRequest,
  loadInterviewCVRequest,
  getInterviewCVRequest
} from '@/api/interviews';
import { downloadFile } from '@/utils/files';

export const state = {
  list: [],
  loading: false,
  totalItems: 0,
  pagination: null,
}

export const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  ADD_TO_LIST(state, item) {
    state.list.push(item);
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_TOTAL_RECORDS(state, count) {
    state.totalItems = count;
  },
  UPDATE_ITEM(state, item) {
    const index = state.list.findIndex(({ id }) => id === item.id)
    if (index + 1) {
      Vue.set(state.list, index, item);
    }
  },
  DELETE_ITEM(state, itemId) {
    const index = state.list.findIndex(({ id }) => id === itemId);
    if (index + 1) {
      state.list.splice(index, 1);
    }
  },
  SET_PAGINATION_SETTING (state, settings) {
    state.pagination = settings
  }
}

export const actions = {
  async getInterviews({ commit }, params) {
    try {
      commit('SET_LIST', []);
      commit('SET_LOADING', true);
      const { data } = await getInterviewsRequest(params);
      if (data.data) {
        commit('SET_LIST', data.data);
        commit('SET_TOTAL_RECORDS', data.total || 0);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async createInterview({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await createInterviewRequest(payload);
      if (data) {
        commit('ADD_TO_LIST', data);
      }
      this._vm.$showSuccessToast('Interview created successfully');
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async updateInterview({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const { data: response } = await updateInterviewRequest(id, data);
      if (response) {
        commit('UPDATE_ITEM', response);
      }
      this._vm.$showSuccessToast('Interview update successfully');
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async deleteInterview({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      await deleteInterviewRequest(id, data);
      commit('DELETE_ITEM', id);
      this._vm.$showSuccessToast('Interview deleted successfully');
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async loadInterviewCV({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const { data: response } = await loadInterviewCVRequest(id, data);
      if (response) {
        commit('UPDATE_ITEM', response);
      }
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async downloadInterviewCV({ commit }, { id, path }) {
    try {
      commit('SET_LOADING', true);
      const data = await getInterviewCVRequest(id);
      const blob = new Blob([data], { type: data.type })
      const urls = path.split('/');
      downloadFile(blob, urls[urls.length - 1]);
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async invite({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      await inviteRequest(payload);
      this._vm.$showSuccessToast('Employee has been successfully invited');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export const getters = {
  interviewsOptions(state) {
    return [
      { value: null, text: 'Choose Interview' },
      ...state.list.map(({ id, name }) => ({ value: id, text: name }))
    ];
  },
  gruppedEventsByDate(state) {
    const dates = new Set(state.list.map(({ created_at }) => moment(created_at).format('DD.MM.YYYY')));

    return [...dates].reduce((acc, date) => {
      acc[date] = state.list
        .filter(({ created_at }) => moment(created_at).format('DD.MM.YYYY') === date)

      return acc;
    }, {});
  },
}
