import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import modules from './modules'

Vue.use(Vuex)


const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
      createPersistedState({
        key: 'bimiboo-storage',
        storage: window.localStorage,
        paths: ['settings', 'employees.filterEmployees', 'feedback.filterStatus', 'sidebar', 'employees.pagination']
      })
  ],
})

export default store

